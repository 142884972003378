import { default as _91_46_46_46404_932Lc1mYZZaMMeta } from "/app/src/pages/[...404].vue?macro=true";
import { default as _91id_936hzAl7LhbPMeta } from "/app/src/pages/accounts/[id].vue?macro=true";
import { default as contractsIJUwWbqTTkMeta } from "/app/src/pages/charts/contracts.vue?macro=true";
import { default as keyblocksR2vr6PTTSXMeta } from "/app/src/pages/charts/keyblocks.vue?macro=true";
import { default as namesScQpSSxexHMeta } from "/app/src/pages/charts/names.vue?macro=true";
import { default as transactionsyNkoYCyKjuMeta } from "/app/src/pages/charts/transactions.vue?macro=true";
import { default as indexI1kEJARmbrMeta } from "/app/src/pages/contract-verification/index.vue?macro=true";
import { default as result9mIJRbjIEFMeta } from "/app/src/pages/contract-verification/result.vue?macro=true";
import { default as _91id_93elMVxtgor5Meta } from "/app/src/pages/contracts/[id].vue?macro=true";
import { default as index2aVVPC7uKGMeta } from "/app/src/pages/contracts/index.vue?macro=true";
import { default as indexdPwi6hPdMfMeta } from "/app/src/pages/index.vue?macro=true";
import { default as _91id_93RlywX5m6kGMeta } from "/app/src/pages/keyblocks/[id].vue?macro=true";
import { default as _91id_93oDftGkctN5Meta } from "/app/src/pages/microblocks/[id].vue?macro=true";
import { default as _91name_93Az8xnVHEWiMeta } from "/app/src/pages/names/[name].vue?macro=true";
import { default as indexUHm9d3A4hbMeta } from "/app/src/pages/names/index.vue?macro=true";
import { default as _91id_93e9UxJ2urYZMeta } from "/app/src/pages/nfts/[id].vue?macro=true";
import { default as indexYsxhjvyZhxMeta } from "/app/src/pages/nfts/index.vue?macro=true";
import { default as _91id_93KFAsu9r6pqMeta } from "/app/src/pages/oracles/[id].vue?macro=true";
import { default as indexpP3o2LVDnvMeta } from "/app/src/pages/oracles/index.vue?macro=true";
import { default as index0anYxncBX1Meta } from "/app/src/pages/privacy-policy/index.vue?macro=true";
import { default as _91id_93WJHjJsQqEMMeta } from "/app/src/pages/search/[id].vue?macro=true";
import { default as _91id_93JWklyDbrUPMeta } from "/app/src/pages/state-channels/[id].vue?macro=true";
import { default as indexiye6WtGMc0Meta } from "/app/src/pages/state-channels/index.vue?macro=true";
import { default as indexPw5gPWdi5tMeta } from "/app/src/pages/terms-of-service/index.vue?macro=true";
import { default as _91id_933PTt44KV7RMeta } from "/app/src/pages/tokens/[id].vue?macro=true";
import { default as indexVyPT5FYCTlMeta } from "/app/src/pages/tokens/index.vue?macro=true";
import { default as _91id_93GUVfHYfa52Meta } from "/app/src/pages/transactions/[id].vue?macro=true";
import { default as indexMjjHIvXpDVMeta } from "/app/src/pages/transactions/index.vue?macro=true";
export default [
  {
    name: _91_46_46_46404_932Lc1mYZZaMMeta?.name ?? "404",
    path: _91_46_46_46404_932Lc1mYZZaMMeta?.path ?? "/:404(.*)*",
    meta: _91_46_46_46404_932Lc1mYZZaMMeta || {},
    alias: _91_46_46_46404_932Lc1mYZZaMMeta?.alias || [],
    redirect: _91_46_46_46404_932Lc1mYZZaMMeta?.redirect,
    component: () => import("/app/src/pages/[...404].vue").then(m => m.default || m)
  },
  {
    name: _91id_936hzAl7LhbPMeta?.name ?? "accounts-id",
    path: _91id_936hzAl7LhbPMeta?.path ?? "/accounts/:id()",
    meta: _91id_936hzAl7LhbPMeta || {},
    alias: _91id_936hzAl7LhbPMeta?.alias || [],
    redirect: _91id_936hzAl7LhbPMeta?.redirect,
    component: () => import("/app/src/pages/accounts/[id].vue").then(m => m.default || m)
  },
  {
    name: contractsIJUwWbqTTkMeta?.name ?? "charts-contracts",
    path: contractsIJUwWbqTTkMeta?.path ?? "/charts/contracts",
    meta: contractsIJUwWbqTTkMeta || {},
    alias: contractsIJUwWbqTTkMeta?.alias || [],
    redirect: contractsIJUwWbqTTkMeta?.redirect,
    component: () => import("/app/src/pages/charts/contracts.vue").then(m => m.default || m)
  },
  {
    name: keyblocksR2vr6PTTSXMeta?.name ?? "charts-keyblocks",
    path: keyblocksR2vr6PTTSXMeta?.path ?? "/charts/keyblocks",
    meta: keyblocksR2vr6PTTSXMeta || {},
    alias: keyblocksR2vr6PTTSXMeta?.alias || [],
    redirect: keyblocksR2vr6PTTSXMeta?.redirect,
    component: () => import("/app/src/pages/charts/keyblocks.vue").then(m => m.default || m)
  },
  {
    name: namesScQpSSxexHMeta?.name ?? "charts-names",
    path: namesScQpSSxexHMeta?.path ?? "/charts/names",
    meta: namesScQpSSxexHMeta || {},
    alias: namesScQpSSxexHMeta?.alias || [],
    redirect: namesScQpSSxexHMeta?.redirect,
    component: () => import("/app/src/pages/charts/names.vue").then(m => m.default || m)
  },
  {
    name: transactionsyNkoYCyKjuMeta?.name ?? "charts-transactions",
    path: transactionsyNkoYCyKjuMeta?.path ?? "/charts/transactions",
    meta: transactionsyNkoYCyKjuMeta || {},
    alias: transactionsyNkoYCyKjuMeta?.alias || [],
    redirect: transactionsyNkoYCyKjuMeta?.redirect,
    component: () => import("/app/src/pages/charts/transactions.vue").then(m => m.default || m)
  },
  {
    name: indexI1kEJARmbrMeta?.name ?? "contract-verification",
    path: indexI1kEJARmbrMeta?.path ?? "/contract-verification",
    meta: indexI1kEJARmbrMeta || {},
    alias: indexI1kEJARmbrMeta?.alias || [],
    redirect: indexI1kEJARmbrMeta?.redirect,
    component: () => import("/app/src/pages/contract-verification/index.vue").then(m => m.default || m)
  },
  {
    name: result9mIJRbjIEFMeta?.name ?? "contract-verification-result",
    path: result9mIJRbjIEFMeta?.path ?? "/contract-verification/result",
    meta: result9mIJRbjIEFMeta || {},
    alias: result9mIJRbjIEFMeta?.alias || [],
    redirect: result9mIJRbjIEFMeta?.redirect,
    component: () => import("/app/src/pages/contract-verification/result.vue").then(m => m.default || m)
  },
  {
    name: _91id_93elMVxtgor5Meta?.name ?? "contracts-id",
    path: _91id_93elMVxtgor5Meta?.path ?? "/contracts/:id()",
    meta: _91id_93elMVxtgor5Meta || {},
    alias: _91id_93elMVxtgor5Meta?.alias || [],
    redirect: _91id_93elMVxtgor5Meta?.redirect,
    component: () => import("/app/src/pages/contracts/[id].vue").then(m => m.default || m)
  },
  {
    name: index2aVVPC7uKGMeta?.name ?? "contracts",
    path: index2aVVPC7uKGMeta?.path ?? "/contracts",
    meta: index2aVVPC7uKGMeta || {},
    alias: index2aVVPC7uKGMeta?.alias || [],
    redirect: index2aVVPC7uKGMeta?.redirect,
    component: () => import("/app/src/pages/contracts/index.vue").then(m => m.default || m)
  },
  {
    name: indexdPwi6hPdMfMeta?.name ?? "index",
    path: indexdPwi6hPdMfMeta?.path ?? "/",
    meta: indexdPwi6hPdMfMeta || {},
    alias: indexdPwi6hPdMfMeta?.alias || [],
    redirect: indexdPwi6hPdMfMeta?.redirect,
    component: () => import("/app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93RlywX5m6kGMeta?.name ?? "keyblocks-id",
    path: _91id_93RlywX5m6kGMeta?.path ?? "/keyblocks/:id()",
    meta: _91id_93RlywX5m6kGMeta || {},
    alias: _91id_93RlywX5m6kGMeta?.alias || [],
    redirect: _91id_93RlywX5m6kGMeta?.redirect,
    component: () => import("/app/src/pages/keyblocks/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93oDftGkctN5Meta?.name ?? "microblocks-id",
    path: _91id_93oDftGkctN5Meta?.path ?? "/microblocks/:id()",
    meta: _91id_93oDftGkctN5Meta || {},
    alias: _91id_93oDftGkctN5Meta?.alias || [],
    redirect: _91id_93oDftGkctN5Meta?.redirect,
    component: () => import("/app/src/pages/microblocks/[id].vue").then(m => m.default || m)
  },
  {
    name: _91name_93Az8xnVHEWiMeta?.name ?? "names-name",
    path: _91name_93Az8xnVHEWiMeta?.path ?? "/names/:name()",
    meta: _91name_93Az8xnVHEWiMeta || {},
    alias: _91name_93Az8xnVHEWiMeta?.alias || [],
    redirect: _91name_93Az8xnVHEWiMeta?.redirect,
    component: () => import("/app/src/pages/names/[name].vue").then(m => m.default || m)
  },
  {
    name: indexUHm9d3A4hbMeta?.name ?? "names",
    path: indexUHm9d3A4hbMeta?.path ?? "/names",
    meta: indexUHm9d3A4hbMeta || {},
    alias: indexUHm9d3A4hbMeta?.alias || [],
    redirect: indexUHm9d3A4hbMeta?.redirect,
    component: () => import("/app/src/pages/names/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93e9UxJ2urYZMeta?.name ?? "nfts-id",
    path: _91id_93e9UxJ2urYZMeta?.path ?? "/nfts/:id()",
    meta: _91id_93e9UxJ2urYZMeta || {},
    alias: _91id_93e9UxJ2urYZMeta?.alias || [],
    redirect: _91id_93e9UxJ2urYZMeta?.redirect,
    component: () => import("/app/src/pages/nfts/[id].vue").then(m => m.default || m)
  },
  {
    name: indexYsxhjvyZhxMeta?.name ?? "nfts",
    path: indexYsxhjvyZhxMeta?.path ?? "/nfts",
    meta: indexYsxhjvyZhxMeta || {},
    alias: indexYsxhjvyZhxMeta?.alias || [],
    redirect: indexYsxhjvyZhxMeta?.redirect,
    component: () => import("/app/src/pages/nfts/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93KFAsu9r6pqMeta?.name ?? "oracles-id",
    path: _91id_93KFAsu9r6pqMeta?.path ?? "/oracles/:id()",
    meta: _91id_93KFAsu9r6pqMeta || {},
    alias: _91id_93KFAsu9r6pqMeta?.alias || [],
    redirect: _91id_93KFAsu9r6pqMeta?.redirect,
    component: () => import("/app/src/pages/oracles/[id].vue").then(m => m.default || m)
  },
  {
    name: indexpP3o2LVDnvMeta?.name ?? "oracles",
    path: indexpP3o2LVDnvMeta?.path ?? "/oracles",
    meta: indexpP3o2LVDnvMeta || {},
    alias: indexpP3o2LVDnvMeta?.alias || [],
    redirect: indexpP3o2LVDnvMeta?.redirect,
    component: () => import("/app/src/pages/oracles/index.vue").then(m => m.default || m)
  },
  {
    name: index0anYxncBX1Meta?.name ?? "privacy-policy",
    path: index0anYxncBX1Meta?.path ?? "/privacy-policy",
    meta: index0anYxncBX1Meta || {},
    alias: index0anYxncBX1Meta?.alias || [],
    redirect: index0anYxncBX1Meta?.redirect,
    component: () => import("/app/src/pages/privacy-policy/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93WJHjJsQqEMMeta?.name ?? "search-id",
    path: _91id_93WJHjJsQqEMMeta?.path ?? "/search/:id()",
    meta: _91id_93WJHjJsQqEMMeta || {},
    alias: _91id_93WJHjJsQqEMMeta?.alias || [],
    redirect: _91id_93WJHjJsQqEMMeta?.redirect,
    component: () => import("/app/src/pages/search/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93JWklyDbrUPMeta?.name ?? "state-channels-id",
    path: _91id_93JWklyDbrUPMeta?.path ?? "/state-channels/:id()",
    meta: _91id_93JWklyDbrUPMeta || {},
    alias: _91id_93JWklyDbrUPMeta?.alias || [],
    redirect: _91id_93JWklyDbrUPMeta?.redirect,
    component: () => import("/app/src/pages/state-channels/[id].vue").then(m => m.default || m)
  },
  {
    name: indexiye6WtGMc0Meta?.name ?? "state-channels",
    path: indexiye6WtGMc0Meta?.path ?? "/state-channels",
    meta: indexiye6WtGMc0Meta || {},
    alias: indexiye6WtGMc0Meta?.alias || [],
    redirect: indexiye6WtGMc0Meta?.redirect,
    component: () => import("/app/src/pages/state-channels/index.vue").then(m => m.default || m)
  },
  {
    name: indexPw5gPWdi5tMeta?.name ?? "terms-of-service",
    path: indexPw5gPWdi5tMeta?.path ?? "/terms-of-service",
    meta: indexPw5gPWdi5tMeta || {},
    alias: indexPw5gPWdi5tMeta?.alias || [],
    redirect: indexPw5gPWdi5tMeta?.redirect,
    component: () => import("/app/src/pages/terms-of-service/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_933PTt44KV7RMeta?.name ?? "tokens-id",
    path: _91id_933PTt44KV7RMeta?.path ?? "/tokens/:id()",
    meta: _91id_933PTt44KV7RMeta || {},
    alias: _91id_933PTt44KV7RMeta?.alias || [],
    redirect: _91id_933PTt44KV7RMeta?.redirect,
    component: () => import("/app/src/pages/tokens/[id].vue").then(m => m.default || m)
  },
  {
    name: indexVyPT5FYCTlMeta?.name ?? "tokens",
    path: indexVyPT5FYCTlMeta?.path ?? "/tokens",
    meta: indexVyPT5FYCTlMeta || {},
    alias: indexVyPT5FYCTlMeta?.alias || [],
    redirect: indexVyPT5FYCTlMeta?.redirect,
    component: () => import("/app/src/pages/tokens/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93GUVfHYfa52Meta?.name ?? "transactions-id",
    path: _91id_93GUVfHYfa52Meta?.path ?? "/transactions/:id()",
    meta: _91id_93GUVfHYfa52Meta || {},
    alias: _91id_93GUVfHYfa52Meta?.alias || [],
    redirect: _91id_93GUVfHYfa52Meta?.redirect,
    component: () => import("/app/src/pages/transactions/[id].vue").then(m => m.default || m)
  },
  {
    name: indexMjjHIvXpDVMeta?.name ?? "transactions",
    path: indexMjjHIvXpDVMeta?.path ?? "/transactions",
    meta: indexMjjHIvXpDVMeta || {},
    alias: indexMjjHIvXpDVMeta?.alias || [],
    redirect: indexMjjHIvXpDVMeta?.redirect,
    component: () => import("/app/src/pages/transactions/index.vue").then(m => m.default || m)
  }
]